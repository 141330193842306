<script setup lang="ts">
import type { Project } from '~/api/types.ts';
import VModal from '~/components/common/VModal.vue';
import ReviewRating from '~/components/rating/ReviewRating.vue';
import SorryModal from '~/components/rating/SorryModal.vue';

const props = defineProps<{ project: Project }>();

const isSorryModalOpen = ref(false);
const isOtzovikModalOpen = ref(false);
const isRatingModalOpen = ref(true);

const emit = defineEmits(['close']);
watch(
  () => !isSorryModalOpen.value && !isRatingModalOpen.value,
  (allModalsClosed) => {
    if (allModalsClosed) emit('close');
  }
);
</script>

<template>
  <v-modal
    v-if="isRatingModalOpen"
    class="review-rating__modal"
    @close="isRatingModalOpen = false"
  >
    <review-rating
      :project="props.project"
      @show-sorry="isSorryModalOpen = true"
      @show-otzovik="isOtzovikModalOpen = true"
      @close="isRatingModalOpen = false"
    />
  </v-modal>
  <sorry-modal
    v-if="isSorryModalOpen"
    @close="isSorryModalOpen = false"
  />
</template>

<style lang="scss">
.review-rating__modal {
  .modal__container {
    padding: 24px;
  }
}
</style>
