<script setup lang="ts">
interface TextareaProps {
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
  modelValue?: number | string;
  maxlength?: number | string;
  id?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  rows?: number | string;
}

withDefaults(defineProps<TextareaProps>(), {
  type: 'text',
  modelValue: undefined,
  maxlength: 100,
  id: undefined,
  label: undefined,
  placeholder: undefined,
  disabled: false,
  required: false,
  rows: 2,
});
const inputEl = ref<HTMLTextAreaElement>();

const emit = defineEmits<{
  (e: 'update:modelValue', v: number | string | null | undefined): void;
}>();

function onInput(e: Event) {
  emit('update:modelValue', (e.target as HTMLTextAreaElement).value);
}
</script>

<template>
  <div class="new-textarea">
    <textarea
      ref="inputEl"
      class="new-textarea__textarea"
      :disabled="disabled"
      :rows="rows"
      :maxlength="maxlength"
      :required="required"
      :placeholder="placeholder"
      @input="onInput"
    />
    <label class="new-textarea__label">
      {{ label }}
    </label>
  </div>
</template>

<style lang="scss">
@import '@/assets/base/scroll';

.new-textarea {
  background: none;
  font-size: 16px;
  position: relative;
}

.new-textarea__length {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 12px;
  line-height: 12px;
  opacity: 0.3;
}

.new-textarea__textarea {
  background: $background-theme-fade;
  outline: none !important;
  border: none;
  color: $foreground-contrast;
  appearance: none;
  font: $base-font;
  width: 100%;
  padding: 10px 12px;
  min-height: 46px;
  border-radius: 12px;
  transition: border-color 0.25s;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  @include styled-scroll($foreground-theme);

  //&:focus {
  //    border-color: $foreground-theme;
  //}

  &::placeholder {
    color: $foreground-gray;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    font: $base-font !important;
  }
}

.new-textarea__label {
  font-weight: 300;
  margin-bottom: 0;
  display: inline-flex;
  white-space: nowrap;
  width: calc(100% - 16px);
  color: $foreground-gray;
  position: absolute;
  transition: all 0.1s linear;
  cursor: text;
  pointer-events: none;
  left: 15px;
  top: 11px;
}

.new-textarea__textarea:focus,
.new-textarea__textarea_filled {
  & + .new-textarea__label {
    font-size: 12px;
    top: 1px;
  }
}

.new-textarea__textarea_error {
  border-bottom: 2px solid $text-status-destructive;
  margin-bottom: -1px;
}
</style>
