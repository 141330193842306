<script setup lang="ts">
import VBadge from '~/components/VBadge.vue';
import VDivider from '~/components/VDivider.vue';

interface PageSideCardProps {
  img?: {
    src: string;
    alt: string;
  };
  badgeText?: string;
  title: string;
  text?: string;
}

const props = defineProps<PageSideCardProps>();
</script>

<template>
  <div class="rate-card rate-card_default">
    <div class="rate-card__header">
      <template v-if="props.img">
        <NuxtImg
          :src="props.img.src"
          :alt="props.img.alt"
        />
        <v-badge
          class="rate-card__badge"
          schema="secondary"
          outline
        >
          {{ props.badgeText }}
        </v-badge>
      </template>
      <h5>
        {{ props.title }}
      </h5>
      <p v-if="props.text">{{ props.text }}</p>
    </div>
    <v-divider
      v-if="props.text"
      is-gray
    />
    <slot />
  </div>
</template>

<style scoped lang="scss">
.rate-card {
  padding: 24px;
  border-radius: 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .rate-card__header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h5 {
      margin: 0;
      color: $foreground-contrast;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
    }

    p {
      color: $foreground-gray;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .rate-card__badge {
    color: $foreground-theme;
  }
}
</style>
