<script setup lang="ts">
import type { Project, Service } from '~/api/types';
import PageSideCard from '~/components/cards/PageSideCard.vue';
import VButton from '~/components/VButton.vue';
import { getActionBlock } from '~/api/projects';

const props = defineProps<{ project: Project; services: Service[] }>();
const emit = defineEmits(['update-action-block']);

const MIN_LENGTH = 1;

const promocode = ref<string | undefined>();
const isValidLength = computed(() => promocode.value && promocode.value.length >= MIN_LENGTH);
const status = ref<'initial' | 'success' | 'error'>('initial');
const loading = ref<boolean>(false);
const submit = async () => {
  const code = promocode.value;
  if (!isValidLength.value) return (status.value = 'error');
  if (!code || loading.value) return;
  loading.value = true;

  await getActionBlock({ promocode: code, projectId: props.project.id, services: props.services })
    .then((cart) => {
      emit('update-action-block', cart);
      status.value = 'success';
    })
    .catch(() => {
      status.value = 'error';
    });

  loading.value = false;
};

const cancel = async () => {
  if (loading.value) return;
  loading.value = true;

  await getActionBlock({ projectId: props.project.id, services: props.services })
    .then((cart) => {
      emit('update-action-block', cart);
    })
    .catch(() => {
      status.value = 'error';
    });

  loading.value = false;
  status.value = 'initial';
  promocode.value = undefined;
};
</script>

<template>
  <page-side-card
    v-if="!props.project.payed_status && props.project.is_owner"
    title="Промокод"
  >
    <v-input
      v-model="promocode"
      button-right
      placeholder="PROMOCODE"
      :disabled="loading || status === 'success'"
      :error="status === 'error'"
      :success="status === 'success'"
      @keydown.enter="submit"
      @input="status = 'initial'"
    >
      <v-button
        style="padding: 0"
        :style="{
          opacity: isValidLength && status === 'initial' ? 100 : 0,
          width: isValidLength && status === 'initial' ? '200px' : '0px',
        }"
        schema="secondary"
        :disabled="loading || status === 'success'"
        @click="submit"
      >
        Применить
      </v-button>
    </v-input>
    <span
      v-if="status === 'error'"
      style="font-size: 14px; color: #e64141; margin-top: -8px; padding-left: 4px"
    >
      Промокод не найден
    </span>
    <v-button
      v-if="status === 'success'"
      schema="text"
      style="margin-top: -8px"
      :disabled="loading"
      @click="cancel"
    >
      Отменить
    </v-button>
  </page-side-card>
</template>

<style scoped lang="scss"></style>
