<template>
  <div :class="[bgClass, loaderClass, 'position-relative overflow-hidden']">
    <div
      class="shimmer position-absolute top-0 right-0 bottom-0 left-0"
      :style="shimmerStyle"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
const LOADER_TYPES = { rectangle: 'rectangle', circle: 'circle' };

const LOADER_CSS_CLASSES = {
  [LOADER_TYPES.rectangle]: 'rounded',
  [LOADER_TYPES.circle]: 'rounded-full',
};

const SHIMMER_COLOR = '#FDFDFF';

const isHexColor = (hexColor: string) => {
  const hex = hexColor.replace('#', '');

  return hexColor.startsWith('#') && hex.length === 6 && !isNaN(Number('0x' + hex));
};

const hexToRgb = (hex: string) => `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`;

const props = defineProps({
  type: {
    type: String,
    default: 'rectangle',
  },
  bgClass: {
    type: String,
    default: 'background-light',
  },
  cssClass: {
    type: String,
    default: '',
  },
  shimmerColor: {
    type: String,
    default: '#FDFDFF',
  },
});

const { type, bgClass, cssClass, shimmerColor } = toRefs(props);

const shimmerStyle = computed(() => {
  const rgb = isHexColor(shimmerColor.value) ? hexToRgb(shimmerColor.value) : SHIMMER_COLOR;

  return {
    backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
  };
});

const loaderClass = computed(() => (cssClass.value ? cssClass.value : LOADER_CSS_CLASSES[type.value]));
</script>

<style lang="scss" scoped>
.shimmer {
  height: 100%;
  width: 100%;
  transform: translateX(-100%);
  animation: shimmer 1.4s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.background-light {
  background: $background-theme-fade;
}
.background-white {
  background: white;
}
.rounded {
  border-radius: 12px !important;
}
.rounded-full {
  border-radius: 9999px !important;
}
</style>
