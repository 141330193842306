<script lang="ts" setup>
import { Download } from 'lucide-vue-next';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import type { Project } from '~/api/types';

const props = defineProps<{ project: Project }>();
const analyticsStore = useAnalyticsStore();
</script>

<template>
  <div class="project-page__main-adv">
    <h2>Текст разделов доступен в расширенной версии</h2>

    <template v-if="project.action_block.show_project_and_presentation_example">
      <p>Для ознакомления с готовым проектом скачайте пример на другую тему</p>
      <a
        class="download-button new-button new-button_primary new-button_sm animation-scale"
        download="Пример проекта Бегемот.pdf"
        href="/project-example.pdf"
        rel="noopener noreferrer"
        target="_blank"
        @click="analyticsStore.downloadProjectExample(props.project.id)"
      >
        <span>Скачать пример</span>
        <Download />
      </a>
    </template>
    <p v-else>Для доступа к полному содержанию необходимо оплатить расширенную версию</p>
  </div>
</template>

<style lang="scss" scoped>
.project-page__main-adv {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  background: $background-theme-fade;
  align-items: start;
  gap: 6px;

  @include media-breakpoint-down(md) {
    gap: 10px;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 1.5rem;
    text-wrap: balance;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
  }
}
</style>
