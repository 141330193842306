<script setup lang="ts">
import type { Project } from '~/api/types.ts';
import ProjectCard from '~/components/cards/ProjectCard.vue';
interface Props {
  similarProjects: Project[];
}
const props = defineProps<Props>();
</script>

<template>
  <div class="similar-projects">
    <h2>Похожие проекты</h2>
    <div class="similar-projects__group">
      <ProjectCard
        v-for="(item, idx) in props.similarProjects"
        :key="idx"
        :schema="item.payed_status ? 'pro-white' : 'default'"
        :project-card="item"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.similar-projects {
  margin-top: 32px;
  margin-bottom: 72px;

  @include media-breakpoint-down(md) {
    padding: 0 20px;
  }

  h2 {
    margin-bottom: 24px;
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .similar-projects__group {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
</style>
