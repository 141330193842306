/**
 * Get plural array [code]
 *
 * @private
 * @param {number} number Число для которого нужно получить индекс
 * @returns {number} Индекс элемента массива
 */
const index = (number) => {
  const i = number % 10;
  return i === 1 ? 0 : i >= 2 && i <= 4 ? 1 : 2;
};

/**
 * Clean number
 *
 * @private
 * @param {number} number - число
 * @returns {number} абсолютное значение числа (беззнаковое)
 */
const cleanNumber = (number) => {
  const cleaned = Math.abs(number);
  if (isNaN(cleaned)) {
    throw new TypeError('Not a Number!');
  }
  return cleaned;
};

/**
 * Plural
 * Функция возвращает существительное или окончание для множественного числа
 * слова на основании числа и массива окончаний
 *
 * @param {number} number - Натуральное число
 * @param {Array} plurals - Массив слов/окончаний для чисел (1, 4, 5)
 * @returns {string} слово/окончание
 * @example
 * plural(7, ['яблоко', 'яблока', 'яблок'])
 * returns 'яблок'
 */
function plural(number, plurals) {
  const tmpNumber = cleanNumber(number) % 100;
  return tmpNumber >= 11 && tmpNumber <= 19 ? plurals[2] : plurals[index(tmpNumber)];
}
/**
 * getPlural
 *
 * @private
 * @param {number} number - число
 * @param {string} word - слово
 * @returns {string} слово/окончание
 */
export default function getPlural(number, word) {
  if (word === 'балл') {
    return plural(number, ['балла', 'баллов', 'баллов']);
  }
  if (word === 'баллы') {
    return plural(number, ['балл', 'балла', 'баллов']);
  }
  if (word === 'место') {
    return plural(number, ['место', 'места', 'мест']);
  }
  if (word === 'год') {
    return plural(number, ['год', 'года', 'лет']);
  }
  if (word === 'месяц') {
    return plural(number, ['месяц', 'месяца', 'месяцев']);
  }

  if (word === 'семестр') {
    return plural(number, ['семестр', 'семестра', 'семестров']);
  }
  if (word === 'дисциплина') {
    return plural(number, ['дисциплина', 'дисциплины', 'дисциплин']);
  }
  if (word === 'вакансия') {
    return plural(number, ['вакансия', 'вакансии', 'вакансий']);
  }
  if (word === 'просмотры') {
    return plural(number, ['просмотр', 'просмотра', 'просмотров']);
  }
}
