<script setup lang="ts">
interface ImageLayoutProps {
  src: string;
  alt: string;
  text?: string;
}

const props = defineProps<ImageLayoutProps>();
</script>

<template>
  <div class="image-layout">
    <NuxtImg
      itemprop="image"
      :src="props.src"
      :alt="props.alt"
    />
    <div
      v-if="props.text"
      class="image-layout__text"
    >
      {{ props.text }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.image-layout {
  img {
    border-radius: 12px;
    border: 1px solid $background-button-secondary-hover;
    width: 100%;
  }

  .image-layout__text {
    color: $foreground-gray;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
