<script setup lang="ts">
import { iconColors } from 'assets/js/utils';
import { Sparkles } from 'lucide-vue-next';
import type { Attachment, Project } from '~/api/types.ts';
import ImageLayout from '~/components/ImageLayout.vue';
import LoadingDots from '~/components/LoadingDots.vue';
import { VueMermaidRender } from 'vue-mermaid-render';
import ChangeStructureButton from '~/components/pages/projects/ChangeStructureButton.vue';

interface ProjectMainBottomProps {
  project: Project;
}

const props = defineProps<ProjectMainBottomProps>();
</script>

<template>
  <div class="project-page__main-bottom">
    <div
      v-if="props.project.paragraphs.length > 1"
      class="project-page__content-list"
    >
      <div class="project-page__content-list__header">
        <h2>Содержание</h2>
        <change-structure-button
          v-if="props.project.is_owner"
          :project="props.project"
        />
      </div>
      <div
        v-for="(item, idx) in props.project.paragraphs"
        :key="idx"
        class="project-page__content-list-item"
      >
        <a
          :href="`#${idx + 1}`"
          class="link link_primary"
          >{{ item.title }}&nbsp;</a
        >
      </div>
    </div>
    <div class="project-page__brief">
      <div
        v-for="(item, idx) in props.project.paragraphs"
        :key="idx"
        class="project-page__chapter"
      >
        <div
          :id="`${idx + 1}`"
          class="project-page__chapter-header"
        >
          <h5>
            {{ item.title }}
          </h5>
        </div>
        <template v-if="item.status">
          <VueMermaidRender
            v-for="attachment in item.attachments?.filter((a: Attachment) => !!a.code)"
            :config="{ theme: 'neutral', look: 'handDrawn' }"
            :content="attachment.code"
          />
          <image-layout
            v-for="attachment in item.attachments?.filter((a: Attachment) => a.type == 'image')"
            v-if="item.attachments?.length"
            :src="attachment.picture_url"
            :alt="attachment.caption || ''"
            itemprop="image"
            :text="attachment.caption"
            class="my-3"
          />
          <image-layout
            v-else
            v-if="item.pictures && item.pictures.matched_images?.length && item.pictures?.matched_images[0]"
            :src="item.pictures?.matched_images[0]"
            :alt="item.pictures.caption || ''"
            itemprop="image"
            :text="item.pictures.caption"
            class="my-3"
          />
        </template>
        <div
          v-if="item.content"
          class="project-page__chapter-content"
        >
          {{ item.content }}
        </div>
        <div
          v-if="!item.status"
          class="project-page__chapter-loader"
        >
          <Sparkles
            :size="20"
            :color="iconColors.primary"
          />
          Генерируем текст
          <loading-dots />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.project-page__main-bottom {
  display: flex;
  flex-direction: column;
  gap: 48px;
  @include media-breakpoint-down(lg) {
    gap: 32px;
  }

  .project-page__content-list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-down(md) {
      gap: 8px;
    }

    .project-page__content-list__header {
      > h2 {
        font-size: 18px;
        margin: 0;
      }

      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }

    .project-page__content-list-item {
      .link_primary {
        color: $foreground-theme;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        transition: 0.2s;

        // &:after {
        //   content: '→';
        //   color: $foreground-theme;
        //   left: 0px;
        //   transition: 0.2s;
        //   position: relative;
        // }

        &:hover {
          color: $foreground-theme;

          &:after {
            left: 4px;
          }
        }
      }
    }
  }

  .project-page__brief {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .project-page__chapter {
      .project-page__chapter-header {
        h5 {
          margin-bottom: 8px;
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;
        }
      }

      .project-page__chapter-content {
        white-space: pre-wrap;
        overflow: hidden;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: justify;
        text-wrap: pretty;

        @include media-breakpoint-down(md) {
          text-align: start;
        }
      }
    }
  }

  .project-page__chapter-loader {
    margin-top: 8px;
    border-radius: 12px;
    border: 1px solid $line-stroke;
    background: $background-theme-fade;
    padding: 10px 16px;

    color: $foreground-theme;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
</style>
