<script setup lang="ts">
import { iconColors } from 'assets/js/utils';
import { Download } from 'lucide-vue-next';
import type { Project } from '~/api/types.ts';
import VBadge from '~/components/VBadge.vue';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';

interface Props {
  project: Project;
}
const props = defineProps<Props>();
const analyticsStore = useAnalyticsStore();
</script>

<template>
  <div class="project-page__main-download">
    Сохраните и отредактируйте текст в&nbsp;Microsoft Word

    <a
      :href="props.project.download_link"
      class="download-docx new-button new-button_sm new-button_outline animation-scale"
      target="_blank"
      rel="noopener noreferrer"
      @click="analyticsStore.downloadText(props.project.id)"
    >
      <Download
        :size="20"
        :color="iconColors.primary"
      />
      Скачать&nbsp;файл
      <v-badge
        class="rate-card__badge"
        outline
      >
        .DOCX
      </v-badge>
    </a>
  </div>
</template>

<style scoped lang="scss">
.project-page__main-download {
  padding: 16px;
  border-radius: 12px;
  background: $background-theme-fade;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}
</style>
