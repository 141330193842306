<script setup lang="ts">
import { iconColors } from 'assets/js/utils';
import { Sparkles } from 'lucide-vue-next';
import type { Attachment, Chapter, Paragraph, Project } from '~/api/types.ts';
import ImageLayout from '~/components/ImageLayout.vue';
import LoadingDots from '~/components/LoadingDots.vue';
import { VueMermaidRender } from 'vue-mermaid-render';
import ChangeStructureButton from '~/components/pages/projects/ChangeStructureButton.vue';

interface ProjectMainBottomProps {
  project: Project;
}

const props = defineProps<ProjectMainBottomProps>();

type StructuredChapter = (Chapter | Paragraph) & {
  paragraphs: Paragraph[];
  is_paragraph: boolean;
};

const structuredChapters = computed(() => {
  const chapters: StructuredChapter[] = [];

  props.project.paragraphs.forEach((paragraph) => {
    if (paragraph.chapter_id) {
      if (chapters.find((ch) => ch.id === paragraph.chapter_id))
        return chapters[chapters.findIndex((ch) => ch.id === paragraph.chapter_id)]?.paragraphs.push(paragraph);
      return chapters.push({ ...paragraph.chapter!, paragraphs: [paragraph], is_paragraph: false });
    } else {
      return chapters.push({ ...paragraph, paragraphs: [paragraph], is_paragraph: true });
    }
  });

  return chapters;
});
</script>

<template>
  <div class="project-page__main-bottom">
    <div
      v-if="Object.keys(structuredChapters).length > 1"
      class="project-page__content-list"
    >
      <div class="project-page__content-list__header">
        <h2>Содержание</h2>
        <change-structure-button
          v-if="props.project.is_owner"
          :project="props.project"
        />
      </div>
      <template
        v-for="(chapter, idx) in structuredChapters"
        :key="idx"
      >
        <a
          :href="'#' + (chapter.paragraphs[0]?.id || chapter.id)"
          class="project-page__content-list-item chapter"
          >{{ chapter.title }}&nbsp;</a
        >
        <template v-if="!chapter.is_paragraph">
          <template
            v-for="(paragraph, idx) in chapter.paragraphs"
            :key="idx"
          >
            <a
              :href="`#${paragraph.id}`"
              class="project-page__content-list-item"
              >{{ paragraph.title }}&nbsp;</a
            >
          </template>
        </template>
      </template>
    </div>
    <div class="project-page__brief">
      <div
        v-for="(paragraph, idx) in props.project.paragraphs"
        :key="idx"
        class="project-page__chapter"
      >
        <div
          :id="paragraph.id"
          class="project-page__chapter-header"
        >
          <h2>
            {{ paragraph.title }}
          </h2>
        </div>
        <template v-if="paragraph.status">
          <VueMermaidRender
            v-for="attachment in paragraph.attachments?.filter((a: Attachment) => !!a.code)"
            :config="{ theme: 'neutral', look: 'handDrawn' }"
            :content="attachment.code"
          />
          <image-layout
            v-for="attachment in paragraph.attachments?.filter((a: Attachment) => a.type == 'image')"
            v-if="paragraph.attachments?.length"
            :src="attachment.picture_url"
            :alt="attachment.caption || ''"
            itemprop="image"
            :text="attachment.caption"
            class="my-3"
          />
          <image-layout
            v-else
            v-if="paragraph.pictures && paragraph.pictures.matched_images?.length && paragraph.pictures?.matched_images[0]"
            :src="paragraph.pictures?.matched_images[0]"
            :alt="paragraph.pictures.caption || ''"
            itemprop="image"
            :text="paragraph.pictures.caption"
            class="my-3"
          />
        </template>
        <div
          v-if="paragraph.content"
          class="project-page__chapter-content"
        >
          {{ paragraph.content }}
        </div>
        <div
          v-if="!paragraph.status"
          class="project-page__chapter-loader"
        >
          <Sparkles
            :size="20"
            :color="iconColors.primary"
          />
          Генерируем текст
          <loading-dots />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.project-page__main-bottom {
  display: flex;
  flex-direction: column;
  gap: 48px;
  @include media-breakpoint-down(lg) {
    gap: 32px;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
  }

  .project-page__content-list {
    display: flex;
    flex-direction: column;

    .project-page__content-list__header {
      > h2 {
        margin: 0;
      }
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      margin-bottom: 8px;
    }

    .project-page__content-list-item {
      color: $foreground-contrast;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      transition: 0.2s;
      text-decoration: underline solid transparent;
      text-underline-offset: 5px;
      padding: 2px 0 2px 20px;

      &:not(.chapter) {
        border-left: 1px solid rgba($foreground-contrast, 0.1);
      }

      &.chapter {
        font-weight: 500;
        padding: 8px 0;
        margin-left: 0;
      }

      &:hover {
        text-decoration: underline solid $foreground-contrast;

        &:after {
          left: 4px;
        }
      }
    }
  }

  .project-page__brief {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .project-page__chapter {
      .project-page__chapter-header {
        h5 {
          margin-bottom: 8px;
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;
        }
      }

      .project-page__chapter-content {
        white-space: pre-wrap;
        overflow: hidden;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: justify;
        text-wrap: pretty;

        @include media-breakpoint-down(md) {
          text-align: start;
        }
      }
    }
  }

  .project-page__chapter-loader {
    margin-top: 8px;
    border-radius: 12px;
    border: 1px solid $line-stroke;
    background: $background-theme-fade;
    padding: 10px 16px;

    color: $foreground-theme;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
</style>
