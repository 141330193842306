<script setup lang="ts">
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import '@webzlodimir/vue-bottom-sheet/dist/style.css';
import type { Project } from '~/api/types.ts';
import ReviewRating from '~/components/rating/ReviewRating.vue';

interface Props {
  isOpen?: boolean;
  project: Project;
}

const props = withDefaults(defineProps<Props>(), {
  isOpen: false,
});

const rateBottomSheet = ref<InstanceType<typeof VueBottomSheet>>();

const open = () => {
  rateBottomSheet.value.open();
};

const close = () => {
  rateBottomSheet.value.close();
};

watch(
  () => props.isOpen,
  (val) => {
    if (val) {
      open();
    } else {
      close();
    }
  }
);

defineEmits(['closedBottomSheet']);
</script>

<template>
  <client-only>
    <vue-bottom-sheet
      ref="rateBottomSheet"
      @closed="$emit('closedBottomSheet')"
    >
      <div class="rate-bottom-sheet">
        <review-rating
          :project="project"
          @success="close"
        />
      </div>
    </vue-bottom-sheet>
  </client-only>
</template>

<style lang="scss">
@import '@/assets/base/scroll';

.rate-bottom-sheet {
  padding: 10px 2px 30px 10px;
}
</style>
