<script setup lang="ts">
import VModal from '~/components/common/VModal.vue';
import type { Project } from '~/api/types';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';

type Props = {
  project: Project;
};

const props = defineProps<Props>();

const analyticsStore = useAnalyticsStore();
const jivoStore = useJivoStore();

const isVoted = useCookie<boolean>('isVotedStructure', { maxAge: 604800 });
const rateBottomSheet = ref<InstanceType<typeof VueBottomSheet>>();
const isModalOpen = ref(false);

const onOpen = () => {
  analyticsStore.clickChangeStructure(props.project.id);
  isModalOpen.value = true;
  rateBottomSheet.value.open();
  jivoStore.isHidden = true;
};

const onClose = () => {
  isModalOpen.value = false;
  rateBottomSheet.value.close();
  jivoStore.isHidden = false;
};

const voteYes = () => {
  analyticsStore.changeStructureVoteYes(props.project.id);
  isVoted.value = true;
  onClose();
};

const voteNo = () => {
  analyticsStore.changeStructureVoteNo(props.project.id);
  isVoted.value = true;
  onClose();
};
</script>

<template>
  <v-button
    v-if="!isVoted"
    schema="outline"
    @click="onOpen"
  >
    Изменить
  </v-button>
  <v-modal
    v-if="isModalOpen"
    class="change-structure-modal"
    @close="onClose"
  >
    <div class="change-structure-modal__body">
      <h1>Редактирование в разработке</h1>
      <p>Скоро здесь появится возможность редактировать содержание. Вам это было бы полезно?</p>
      <div class="change-structure-modal__body__button-group">
        <v-button
          schema="outline"
          medium
          @click="voteYes"
        >
          Полезно
        </v-button>
        <v-button
          schema="outline"
          medium
          @click="voteNo"
        >
          Бесполезно
        </v-button>
      </div>
    </div>
  </v-modal>
  <client-only>
    <vue-bottom-sheet
      ref="rateBottomSheet"
      @closed="
        () => {
          if (isModalOpen) onClose();
        }
      "
    >
      <div class="change-structure-modal">
        <div
          class="change-structure-modal__body"
          style="padding: 24px; text-align: start"
        >
          <h1>Редактирование в разработке</h1>
          <p>Скоро здесь появится возможность редактировать содержание. Вам это было бы полезно?</p>
          <div class="change-structure-modal__body__button-group">
            <v-button
              schema="outline"
              medium
              @click="voteYes"
            >
              Полезно
            </v-button>
            <v-button
              schema="outline"
              medium
              @click="voteNo"
            >
              Бесполезно
            </v-button>
          </div>
        </div>
      </div>
    </vue-bottom-sheet>
  </client-only>
</template>

<style scoped lang="scss">
.change-structure-modal {
  .change-structure-modal__body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    text-align: center;

    > h1 {
      font-size: 22px;
      margin: 0;
    }

    > p {
      margin-top: -8px;
      font-size: 16px;
    }

    .change-structure-modal__body__button-group {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 8px;

      > button {
        width: 100%;
        text-wrap: nowrap;
      }
    }
  }
}
</style>
