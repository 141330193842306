<script setup lang="ts">
import type { Project } from '~/api/types';

type Props = {
  project: Project;
};

const props = defineProps<Props>();

const novaPath = computed(() => `https://begemot.ai/nova/resources/projects/${props.project.slug}`);
</script>

<template>
  <div class="project-admin-panel">
    <a
      :href="novaPath"
      style="text-decoration: unset"
      target="_blank"
    >
      <v-button
        style="width: 100%"
        schema="outline"
      >
        Перейти в Nova
      </v-button>
    </a>
  </div>
</template>

<style scoped lang="scss">
.project-admin-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  background: white;
  color: $foreground-contrast;
}
</style>
