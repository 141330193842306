<script setup lang="ts">
import { Download, Lock, Sparkles } from 'lucide-vue-next';
import type { EntityType, Project } from '~/api/types.ts';
import PageSideCard from '~/components/cards/PageSideCard.vue';
import LoadingDots from '~/components/LoadingDots.vue';
import ActionBlock from '~/components/pages/projects/ActionBlock.vue';
import RateBottomSheet from '~/components/rating/RateBottomSheet.vue';
import RateModal from '~/components/rating/RateModal.vue';
import { entities } from '~/enitityHelper';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import VButton from '~/components/VButton.vue';
import OtzovikModal from '~/components/rating/StarRating/OtzovikModal.vue';
import VDropdown from '~/components/common/VDropdown.vue';

interface Props {
  project: Project;
}
const route = useRoute();
const props = defineProps<Props>();

const texts = entities[(props.project.entity as EntityType) || 'project'];
const showRateModal = ref(props.project.is_owner && !props.project.rated_dt && route.query.rating === null);
const analyticsStore = useAnalyticsStore();
const isOtzovikOpen = ref<boolean>(false);
</script>

<template>
  <div class="project-page__side">
    <div class="project-page__side_sticky">
      <page-side-card
        v-if="props.project.payed_status && props.project.is_owner"
        :text="props.project.text_status ? texts.downloadCard.description : texts.isGeneratingCard.description"
        :title="props.project.text_status ? texts.downloadCard.title : texts.isGeneratingCard.title"
      >
        <div class="download-buttons">
          <v-dropdown
            v-if="props.project.download_link && props.project.download_link_pdf && props.project.text_status"
            class="download-buttons__dropdown"
            width="70%"
          >
            <v-button
              schema="outline"
              medium
              class="download-button"
            >
              <span>Документ</span>
              <Download
                width="20"
                height="20"
              />
            </v-button>
            <template #body>
              <a
                :href="props.project.download_link"
                class="dropdown-download-button new-button new-button_md new-button_outline animation-scale"
                target="_blank"
                rel="noopener noreferrer"
                @click="analyticsStore.downloadText(props.project.id)"
              >
                <Download
                  width="20"
                  height="20"
                />
                <span>Скачать в DOCX</span>
              </a>
              <a
                :href="props.project.download_link_pdf"
                class="dropdown-download-button new-button new-button_md new-button_outline animation-scale"
                target="_blank"
                rel="noopener noreferrer"
                @click="analyticsStore.downloadText(props.project.id)"
              >
                <Download
                  width="20"
                  height="20"
                />
                <span>Скачать в PDF</span>
              </a>
            </template>
          </v-dropdown>
          <button
            v-else-if="!props.project.text_status"
            v-tooltip="'Документ генерируется'"
            disabled
            class="download-button new-button new-button_md new-button_outline"
          >
            <span>Документ</span>
            <LoadingDots />
          </button>

          <a
            v-if="props.project.download_link_pptx && props.project.presentation_status === 1"
            :href="props.project.download_link_pptx!"
            class="download-button new-button new-button_md new-button_outline animation-scale"
            target="_blank"
            rel="noopener noreferrer"
            @click="analyticsStore.downloadSlides(props.project.id)"
          >
            <span>Презентация</span>
            <Download
              width="20"
              height="20"
            />
          </a>
          <button
            v-else-if="props.project.presentation_status === 0"
            v-tooltip="'Презентация генерируется'"
            disabled
            class="download-button new-button new-button_md new-button_outline"
          >
            <span>Презентация</span>
            <LoadingDots />
          </button>
          <button
            v-else
            v-tooltip="'Презентация будет доступна после оплаты'"
            disabled
            class="download-button new-button new-button_md new-button_outline"
          >
            <span>Презентация</span>
            <Lock
              width="20"
              height="20"
            />
          </button>
        </div>
      </page-side-card>

      <action-block
        v-if="props.project && props.project.action_block"
        :project="props.project"
      >
      </action-block>
      <page-side-card
        v-if="props.project && props.project.payed_status && props.project.is_owner && !props.project.long_poll && !props.project.rated_dt"
        :title="texts.rateCard.title"
        :text="texts.rateCard.description"
      >
        <v-button
          class="w-100 animation-scale"
          schema="secondary"
          @click="showRateModal = true"
        >
          Оценить
        </v-button>
      </page-side-card>

      <page-side-card
        v-if="props.project.is_owner && props.project.rating && props.project.rating >= 4 && props.project.entity != 'final-essay'"
        title="Вернем деньги за проект"
        text="Оставьте отзыв на Отзовик.ру, и мы вернем вам полную стоимость проекта!"
      >
        <v-button
          schema="secondary"
          @click="isOtzovikOpen = true"
          >Вернуть деньги</v-button
        >
      </page-side-card>
      <page-side-card
        :title="texts.otherProjectCard.title"
        :text="texts.otherProjectCard.description"
      >
        <v-button
          class="w-100 animation-scale"
          :schema="
            props.project && props.project.payed_status && props.project.is_owner && !props.project.long_poll ? 'primary' : 'secondary'
          "
          @click="navigateTo('/')"
        >
          Создать
          <template #iconLeft>
            <Sparkles :size="20" />
          </template>
        </v-button>
      </page-side-card>
    </div>
  </div>
  <rate-modal
    v-if="showRateModal"
    :project="props.project"
    @close="showRateModal = false"
  />
  <otzovik-modal
    :project="props.project"
    :is-open-by-click="isOtzovikOpen"
    @close="isOtzovikOpen = false"
  />
  <rate-bottom-sheet
    :project="props.project"
    :is-open-by-click="showRateModal"
    @closed-bottom-sheet="showRateModal = false"
  />
</template>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.project-page__side_sticky {
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: sticky;
  top: 96px;

  .download-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .download-button {
      width: 100%;
      justify-content: space-between !important;
      flex-direction: row;
    }

    .download-buttons__dropdown {
      * {
        margin-top: 0 !important;
      }

      .dropdown-download-button {
        width: 100%;
        text-wrap: nowrap;
        background: white;

        &:hover {
          background: $background-button-ghost-hover;
        }

        border-radius: 0;
      }
    }
  }
}

.muted {
  opacity: 70%;
  &:hover {
    cursor: default;
  }
}
</style>
