<script setup lang="ts">
import { iconColors } from 'assets/js/utils';
import {
  Briefcase,
  ClipboardList,
  Dna,
  Download,
  Lightbulb,
  Lock,
  MessageCircleQuestion,
  Rocket,
  RussianRuble,
  Target,
  UsersRound,
} from 'lucide-vue-next';
import { Swiper } from 'swiper';
import type { Project } from '~/api/types.ts';
import SkeletonLoader from '~/components/common/SkeletonLoader.vue';
import ImageLayout from '~/components/ImageLayout.vue';
import SnippetLink from '~/components/SnippetLink.vue';
import TitleLayout from '~/components/TitleLayout.vue';
import VDivider from '~/components/VDivider.vue';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import LoadingDots from '~/components/LoadingDots.vue';
import VButton from '~/components/VButton.vue';
import VDropdown from '~/components/common/VDropdown.vue';

interface ProjectMainTopProps {
  project: Project;
}

const swiperInit = ref(false);
const props = defineProps<ProjectMainTopProps>();

const dData = reactive({ swiper: {} });
const snippetLinkSwiper = ref();

onMounted(() => {
  dData.swiper = new Swiper(snippetLinkSwiper.value, {
    slidesPerView: 1.3,
    spaceBetween: 16,
    breakpoints: {
      768: {
        slidesPerView: 3.2,
      },
    },
    on: {
      beforeInit: function () {
        swiperInit.value = true;
      },
    },
  });
});

const analyticsStore = useAnalyticsStore();

const mainInfo = ref([
  {
    title: 'Идея',
    content: props.project?.idea,
    icon: Lightbulb,
  },
  {
    title: 'Цель',
    content: props.project?.goal,
    icon: Target,
  },
  {
    title: 'Проблема',
    content: props.project?.problem,
    icon: MessageCircleQuestion,
  },
  {
    title: 'Актуальность',
    content: props.project?.relevance,
    icon: Dna,
  },
  {
    title: 'Целевая аудитория',
    content: props.project?.target_auditory,
    icon: UsersRound,
  },
  {
    title: 'Задачи',
    content: props.project?.tasks,
    icon: ClipboardList,
  },
  {
    title: 'Роли в проекте',
    content: props.project?.roles,
    icon: Briefcase,
  },
  {
    title: 'Ресурсы',
    content: props.project?.resources,
    icon: RussianRuble,
  },
  {
    title: 'Продукт',
    content: props.project?.product,
    icon: Rocket,
  },
]);
</script>

<template>
  <div class="project-page__main-top">
    <title-layout :project="props.project" />
    <div
      v-if="props.project.is_owner && !!props.project.payed_status"
      class="project-page__main-top__download-block"
    >
      <v-divider is-gray />
      <div class="project-page__main-top__download-buttons">
        <v-dropdown
          v-if="props.project.download_link && props.project.download_link_pdf && props.project.text_status"
          width="70%"
          class="download-buttons__dropdown"
        >
          <v-button
            schema="outline"
            medium
            class="download-button"
          >
            <span>Документ</span>
            <Download
              width="20"
              height="20"
            />
          </v-button>
          <template #body>
            <a
              :href="props.project.download_link"
              class="dropdown-download-button new-button new-button_md new-button_outline animation-scale w-full"
              target="_blank"
              rel="noopener noreferrer"
              @click="analyticsStore.downloadText(props.project.id)"
            >
              <Download
                width="20"
                height="20"
              />
              <span>Скачать в DOCX</span>
            </a>
            <a
              :href="props.project.download_link_pdf"
              class="dropdown-download-button new-button new-button_md new-button_outline animation-scale w-full"
              target="_blank"
              rel="noopener noreferrer"
              @click="analyticsStore.downloadText(props.project.id)"
            >
              <Download
                width="20"
                height="20"
              />
              <span>Скачать в PDF</span>
            </a>
          </template>
        </v-dropdown>
        <button
          v-else-if="!props.project.text_status"
          v-tooltip="'Документ генерируется'"
          disabled
          class="download-button new-button new-button_md new-button_outline"
        >
          <span>Документ</span>
          <LoadingDots />
        </button>

        <a
          v-if="props.project.download_link_pptx && props.project.presentation_status === 1"
          :href="props.project.download_link_pptx!"
          class="download-button new-button new-button_md new-button_outline animation-scale"
          target="_blank"
          rel="noopener noreferrer"
          @click="analyticsStore.downloadSlides(props.project.id)"
        >
          <span>Презентация</span>
          <Download
            width="20"
            height="20"
          />
        </a>
        <button
          v-else-if="props.project.presentation_status === 0"
          v-tooltip="'Презентация генерируется'"
          disabled
          class="download-button new-button new-button_md new-button_outline"
        >
          <span>Презентация</span>
          <LoadingDots />
        </button>
        <button
          v-else
          v-tooltip="'Презентация будет доступна после оплаты'"
          disabled
          class="download-button new-button new-button_md new-button_outline"
        >
          <span>Презентация</span>
          <Lock
            width="20"
            height="20"
          />
        </button>
      </div>
    </div>

    <image-layout
      v-if="props.project.picture_url"
      :src="props.project.picture_url"
      :alt="props.project.picture_prompt"
      itemprop="image"
    />
    <div
      v-if="props.project.entity != 'final-essay'"
      class="project-page__main-group"
    >
      <p
        v-if="props.project.description.length"
        class="description"
        itemprop="description"
      >
        {{ props.project.description }}
      </p>
      <div
        v-show="props.project.links.length > 0 && !swiperInit"
        class="project-page__links-skeleton"
      >
        <skeleton-loader class="w-100 h-32" />
        <skeleton-loader class="w-100 h-32" />
        <skeleton-loader class="w-100 h-32" />
      </div>
      <div
        v-show="props.project.links.length > 0 && swiperInit"
        ref="snippetLinkSwiper"
        class="swiper project-page__links"
      >
        <div class="swiper-wrapper">
          <div
            v-for="link in props.project.links"
            :key="link.title"
            class="swiper-slide h-auto"
          >
            <snippet-link :snippet-link="link" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="mainInfo.length"
      class="project-page__main-info"
    >
      <template
        v-for="(item, idx) in mainInfo"
        :key="idx"
      >
        <div
          v-if="item.content"
          class="main-info__item"
        >
          <div class="main-info__item-header">
            <h5>
              {{ item.title }}
            </h5>
            <component
              :is="item.icon"
              :size="24"
              :color="iconColors.primaryLight"
            />
          </div>
          <div class="main-info__item-content">
            {{ item.content }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.project-page__main-top {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @include media-breakpoint-down(lg) {
    gap: 24px;
  }

  .project-page__main-top__download-block {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .project-page__main-top__download-buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include media-breakpoint-up(xl) {
        display: none;
      }

      .download-button {
        width: 100%;
        justify-content: space-between !important;
        flex-direction: row;
      }

      .download-buttons__dropdown {
        * {
          margin-top: 0 !important;
        }

        .dropdown-download-button {
          width: 100%;
          text-wrap: nowrap;
          background: white;

          &:hover {
            background: $background-button-ghost-hover;
          }

          border-radius: 0;
        }
      }
    }
  }

  .project-page__title-layout {
    //margin-bottom: 40px;
  }

  .project-page__image-layout {
    //margin-bottom: 40px;
  }

  .project-page__main-group {
    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 24px;
      text-wrap: pretty;
      text-align: justify;

      @include media-breakpoint-down(md) {
        text-align: start;
      }
    }

    .project-page__links {
      margin-top: 24px;

      .snippet-link {
        height: 100%;
      }

      .swiper-slide {
        padding: 12px 0;
      }
    }

    .project-page__links-skeleton {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(3, 80%);
        overflow: hidden;
      }
    }
  }

  .project-page__main-info {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include media-breakpoint-down(md) {
      gap: 24px;
    }

    .main-info__item {
      .main-info__item-header {
        display: flex;
        gap: 8px;
        margin-bottom: 8px;

        h5 {
          margin-bottom: 0;
          font-size: 18px;
        }
      }

      .main-info__item-content {
        white-space: pre-wrap;
        overflow: hidden;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-wrap: pretty;

        text-align: justify;
        @include media-breakpoint-down(md) {
          text-align: start;
        }
      }
    }
  }
}
</style>
