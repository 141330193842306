<script setup lang="ts">
import VModal from '~/components/common/VModal.vue';
import VDivider from '~/components/VDivider.vue';
import VButton from '~/components/VButton.vue';

// defineProps<{ project: Project }>();

const emit = defineEmits(['close']);
const isJivoBlocked = ref(false);

const openJivo = () => {
  if (window.jivo_api) {
    emit('close');
    window.jivo_api.open();
  } else isJivoBlocked.value = true;
};
</script>

<template>
  <v-modal
    class="sorry-modal"
    :title="`Мы сожалеем, что у вас остались негативные впечатления`"
    @close="emit('close')"
  >
    <div class="sorry-modal__container">
      <p class="sorry-modal__text">
        Мы стремимся сделать наш сервис лучше и ценим ваш отзыв. Если вы готовы поделиться подробностями или у вас есть предложения, как мы
        можем улучшить ситуацию, мы будем рады выслушать вас.
      </p>
      <v-divider is-gray />
      <v-button
        class="sorry-modal__support-button"
        @click="openJivo()"
        >Написать в поддержку</v-button
      >
      <template v-if="isJivoBlocked">
        <span class="sorry-modal__error">
          Не удалось открыть виджет поддержки, так как у вас включен <b>блокировщик рекламы</b>. Добавьте веб-сайт <u>begemot.ai</u> в
          исключения или выключите блокировщик рекламы, потом обновите страницу.
        </span>
      </template>
    </div>
  </v-modal>
</template>

<style lang="scss">
.sorry-modal {
  .modal__container {
    padding: 24px;
  }

  .sorry-modal__container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .sorry-modal__text {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: $foreground-gray;
    }

    .sorry-modal__support-button {
      width: 100%;
    }

    .sorry-modal__error {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $text-status-destructive;
      hyphens: auto;
    }
  }
}
</style>
