<script setup lang="ts"></script>

<template>
  <div class="loading-dots">
    <span class="loading-dots__item" />
    <span class="loading-dots__item" />
    <span class="loading-dots__item" />
  </div>
</template>

<style scoped lang="scss">
.loading-dots {
  align-items: center;
  display: flex;
  justify-content: center;
}

.loading-dots__item {
  animation: dot ease-in-out 1s infinite;
  background-color: $foreground-theme;
  display: inline-block;
  height: 4px;
  margin: 2px;
  width: 4px;
  border-radius: 20px;
}

.loading-dots__item:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading-dots__item:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.7);
  }
  100% {
    transform: scale(1);
  }
}
</style>
